// Generated by Framer (f7d95e4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const cycleOrder = ["IIUnS9qJQ", "xnDE0Xi9W"];

const serializationHash = "framer-0AqEN"

const variantClassNames = {IIUnS9qJQ: "framer-v-9ebeaq", xnDE0Xi9W: "framer-v-1xo64j3"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Black: "IIUnS9qJQ", White: "xnDE0Xi9W"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "IIUnS9qJQ"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "IIUnS9qJQ", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Link href={{webPageId: "augiA20Il"}} motionChild nodeId={"IIUnS9qJQ"} scopeId={"stizZ6vZ7"}><Image {...restProps} {...gestureHandlers} as={"a"} background={{alt: "", fit: "fit", intrinsicHeight: 48, intrinsicWidth: 175, loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 189, pixelWidth: 842, positionX: "center", positionY: "center", sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/3gM9GfdX1aF1OUmMiq3psRtO0.png?scale-down-to=512", srcSet: "https://framerusercontent.com/images/3gM9GfdX1aF1OUmMiq3psRtO0.png?scale-down-to=512 512w,https://framerusercontent.com/images/3gM9GfdX1aF1OUmMiq3psRtO0.png 842w"}} className={`${cx(scopingClassNames, "framer-9ebeaq", className, classNames)} framer-p011ac`} data-framer-name={"Black"} layoutDependency={layoutDependency} layoutId={"IIUnS9qJQ"} ref={refBinding} style={{...style}} {...addPropertyOverrides({xnDE0Xi9W: {"data-framer-name": "White", background: {alt: "", fit: "fit", intrinsicHeight: 48, intrinsicWidth: 175, loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 189, pixelWidth: 842, positionX: "center", positionY: "center", sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/a2NtzYPkZMfJAUlljDuWGwN8.png?scale-down-to=512", srcSet: "https://framerusercontent.com/images/a2NtzYPkZMfJAUlljDuWGwN8.png?scale-down-to=512 512w,https://framerusercontent.com/images/a2NtzYPkZMfJAUlljDuWGwN8.png 842w"}}}, baseVariant, gestureVariant)}/></Link></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-0AqEN.framer-p011ac, .framer-0AqEN .framer-p011ac { display: block; }", ".framer-0AqEN.framer-9ebeaq { height: 24px; overflow: visible; position: relative; text-decoration: none; width: 88px; }", ".framer-0AqEN.framer-v-1xo64j3.framer-9ebeaq { aspect-ratio: 3.6458333333333335 / 1; height: var(--framer-aspect-ratio-supported, 24px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 88
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"xnDE0Xi9W":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerstizZ6vZ7: React.ComponentType<Props> = withCSS(Component, css, "framer-0AqEN") as typeof Component;
export default FramerstizZ6vZ7;

FramerstizZ6vZ7.displayName = "Misc/Logo";

FramerstizZ6vZ7.defaultProps = {height: 24, width: 88};

addPropertyControls(FramerstizZ6vZ7, {variant: {options: ["IIUnS9qJQ", "xnDE0Xi9W"], optionTitles: ["Black", "White"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerstizZ6vZ7, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})